<template>
  <div>
    <v-row class="mx-0">
      <!-- Partes temporales -->
      <v-col cols="12">
        <div class="card">
          <div class="card-header card-header-icon card-header-rose">
            <div class="card-icon">
              <i class="material-icons">sticky_note_2</i>
            </div>
            <h3 class="card-title">Folio Temporal</h3>
          </div>
          <div class="card-body">
            <v-row v-for="parte in temporales" v-bind:key="parte.id">
              <v-col>
                <TicketBitacoraNew
                  v-bind:parte="parte"
                  @clicked="seleccionParte"
                />
              </v-col>
              <v-col>
                <v-btn @click="sincronizar">Sincronizar Turno</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>

      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <!-- bitacora de carga -->
          <v-expansion-panel v-if="mostrar.carga">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              <div>
                BITACORA DE CARGA SMOLT<v-icon
              color="orange"
              title="Guadado Temporal"
              v-if="carga.temporal == 1"
              >mdi-clock</v-icon
            >
            <v-icon
              color="green"
              title="Guadado Definitivo"
              v-if="carga.temporal == 0"
              >mdi-check-circle</v-icon
            >
            </div>
              
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="2">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Folio Parte"
                    label="Folio Parte"
                    v-model="parte.folio"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <fieldset class="px-2 py-2 elevation-3" v-if="carga.es_nueva">
                <legend class="rounded-sm px-2 py-1">
                  INFORMACION GENERAL
                </legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-pound"
                      placeholder="N° Camiones"
                      label="N° Camiones"
                      v-model="carga.camiones"
                      dense
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="carga.especies_id"
                      :items="especies"
                      item-text="nombre"
                      item-value="id"
                      label="Especie"
                      placeholder="Seleccione especie"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-swim"
                      placeholder="Días de ayuno"
                      label="Días de ayuno"
                      v-model="carga.dias_ayuno"
                      type="text"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <!--PARAMETROS-->
                  <v-col cols="4" v-for="p in parameters" :key="`par_${p.id}`">
                    <v-select
                      v-model="p.valor"
                      prepend-icon="mdi-form-select"
                      :items="p.opciones"
                      :label="p.nombre"
                      item-text="nombre"
                      item-value="id"
                      dense
                    ></v-select>
                  </v-col>
                  <!--PARAMETROS-->

                  <v-col :cols="`${mostrar.segundo_centro_descarga?'4':'2'}`">
                    <v-select
                      prepend-icon="mdi-gate"
                      v-model="carga.descarga_1_empresas_id"
                      :items="empresas"
                      item-text="razon_social"
                      item-value="id"
                      label="Empresa"
                      @change="cargarCentrosEmpresa"
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      v-model="carga.descarga_1_centros_id"
                      :items="centros"
                      item-text="nombre"
                      item-value="id"
                      :label="`${texto_centro_uno}`"
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2" v-if="mostrar.segundo_centro_descarga">
                    <v-select
                      v-model="carga.descarga_2_centros_id"
                      :items="centros"
                      item-text="nombre"
                      item-value="id"
                      label="Centro de Descarga 2"
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      :placeholder="nbodega1"
                      :label="nbodega1"
                      v-model="carga.sello_babor"
                      dense
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      :placeholder="nbodega2"
                      :label="nbodega2"
                      v-model="carga.sello_central"
                      dense
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="parte.wellboats_id != 7">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      placeholder="N° sello estribor"
                      label="N° sello estribor"
                      v-model="carga.sello_estribor"
                      dense
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      placeholder="N° sello Yoma 1"
                      label="N° sello Yoma 1"
                      v-model="carga.sello_yoma1"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      placeholder="N° sello Yoma 2"
                      label="N° sello Yoma 2"
                      v-model="carga.sello_yoma2"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </fieldset>

              <fieldset class="px-2 py-2 elevation-3" v-if="!carga.es_nueva">
                <legend class="rounded-sm px-2 py-1">
                  INFORMACION GENERAL
                </legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-pound"
                      placeholder="N° Camiones"
                      label="N° Camiones"
                      v-model="carga.camiones"
                      dense
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="carga.especie"
                      :items="especies"
                      item-text="nombre"
                      item-value="id"
                      label="Especie"
                      placeholder="Seleccione especie"
                      return-object
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-swim"
                      placeholder="Días de ayuno"
                      label="Días de ayuno"
                      v-model="carga.dias_ayuno"
                      type="text"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-swim"
                      placeholder="Natacion en bodegas"
                      label="Natacion en bodegas"
                      v-model="carga.natacion"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-air-conditioner"
                      placeholder="Boqueo en bodegas"
                      label="Boqueo en bodegas"
                      v-model="carga.boqueo"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-fish"
                      placeholder="Descamacion"
                      label="Descamacion"
                      v-model="carga.descamacion"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-medical-bag"
                      placeholder="Peces con heridas"
                      label="Peces con heridas"
                      v-model="carga.heridas"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-gate"
                      placeholder="Mortalidad en cerco"
                      label="Mortalidad en cerco"
                      v-model="carga.mortalidad_cerco"
                      counter="20"
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-select
                      prepend-icon="mdi-gate"
                      v-model="carga.parte.empresa"
                      :items="empresas"
                      item-text="razon_social"
                      item-value="id"
                      label="Empresa"
                      return-object
                      @change="cargarCentrosEmpresa"
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      v-model="carga.parte.centro"
                      :items="centros"
                      item-text="nombre"
                      item-value="id"
                      label="Centro de Descarga"
                      return-object
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      :placeholder="nbodega1"
                      :label="nbodega1"
                      v-model="carga.sello_babor"
                      dense
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      :placeholder="nbodega2"
                      :label="nbodega2"
                      v-model="carga.sello_central"
                      dense
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="parte.wellboats_id != 7">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      placeholder="N° sello estribor"
                      label="N° sello estribor"
                      v-model="carga.sello_estribor"
                      dense
                      type="text"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      placeholder="N° sello Yoma 1"
                      label="N° sello Yoma 1"
                      v-model="carga.sello_yoma1"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      prepend-icon="mdi-seal"
                      placeholder="N° sello Yoma 2"
                      label="N° sello Yoma 2"
                      v-model="carga.sello_yoma2"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </fieldset>

              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">Bodegas</legend>
                <v-btn @click="agregarCargaBodega" color="green"
                  ><v-icon>exposure_plus_1</v-icon></v-btn
                >
                <v-simple-table dense class="mx-2 my-2 elevation-3">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text">
                          N°
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Bodega
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Número de Peces
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Peso Promedio (Kg)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Biomasa Estimada (Kg)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Densidad de Carga (Kg/m3)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          N° Estanques
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora Inicio
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora Termino
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(detalle, index) in carga.bodegas"
                        :key="detalle.numero"
                      >
                        <td class="text-center">{{ detalle.numero }}</td>
                        <td class="text-center">
                          <v-select
                            v-model="detalle.bodegas_id"
                            :items="bodegas"
                            item-text="nombre"
                            item-value="id"
                            label="Bodega"
                            placeholder="Seleccione bodega"
                          ></v-select>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            class="input-right"
                            v-model="detalle.peces"
                            type="number"
                            @input="recalcular(detalle, index)"
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            class="input-right"
                            v-model="detalle.peso"
                            type="number"
                            @input="recalcular(detalle, index)"
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            class="input-right"
                            v-model="detalle.biomasa"
                            type="number"
                            readonly
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            class="input-right"
                            v-model="detalle.densidad"
                            type="number"
                            readonly
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            class="input-center"
                            v-model="detalle.estanques"
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-datetime-picker
                            label="Inicio (Fecha Hora)"
                            v-model="detalle.hora_inicio"
                            dateFormat="dd-MM-yyyy"
                            :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }"
                            :timePickerProps="{ format: '24hr' }"
                          >
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td class="text-center">
                          <v-datetime-picker
                            label="Término (Fecha Hora)"
                            v-model="detalle.hora_termino"
                            dateFormat="dd-MM-yyyy"
                            :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }"
                            :timePickerProps="{ format: '24hr' }"
                          >
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td class="text-center">
                          <a @click="eliminarBodega(index)"
                            ><span class="material-icons">delete</span></a
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2">Total/Prom</td>
                        <td>
                          <v-text-field
                            class="input-right"
                            v-model="totalPeces"
                            type="number"
                            readonly
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            class="input-right"
                            v-model="totalPeso"
                            type="number"
                            readonly
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            class="input-right"
                            v-model="totalBiomasa"
                            type="number"
                            readonly
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            class="input-right"
                            v-model="totalDensidad"
                            type="number"
                            readonly
                          ></v-text-field>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </fieldset>

              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Parámetros Registrados en bodegas Wellboat
                </legend>
                <v-btn @click="agregarLance" color="green"
                  ><v-icon>exposure_plus_1</v-icon></v-btn
                >
                <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-center blue-grey darken-4 white-text"
                          rowspan="2"
                        >
                          Número
                        </th>
                        <th
                          class="text-center blue-grey darken-4 white-text"
                          rowspan="2"
                        >
                          Hora medición
                        </th>
                        <th
                          class="text-center blue-grey darken-4 white-text"
                          colspan="4"
                        >
                          Parámetros Ambientales
                        </th>
                        <th
                          class="text-center blue-grey darken-4 white-text"
                          rowspan="2"
                        >
                          Acciones
                        </th>
                      </tr>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text">
                          Oxigeno (mg/L)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Saturacion (%)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          T (°C)
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          PH
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(lance, idx) in carga.lances"
                        :key="lance.numero"
                      >
                        <td style="border-right: 1px solid black;">
                          {{ lance.numero }}
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-datetime-picker
                            label="Medicion (Fecha Hora)"
                            v-model="lance.hora_medicion"
                            dateFormat="dd-MM-yyyy"
                            :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }"
                            :timePickerProps="{ format: '24hr' }"
                          >
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td>
                          <v-text-field
                            v-model="lance.oxigeno"
                            suffix="mg/L"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model="lance.saturacion"
                            suffix="%"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-text-field
                            v-model="lance.temperatura"
                            suffix="° C"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td style="border-right: 1px solid black;">
                          <v-text-field
                            v-model="lance.ph"
                            type="number"
                          ></v-text-field>
                        </td>
                        <td>
                          <a @click="eliminarLance(idx)"
                            ><span class="material-icons">delete</span></a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </fieldset>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    solo
                    counter="500"
                    label="Observaciones"
                    v-model="carga.observaciones"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-if="capitanes.length > 0"
                    v-model="carga.capitan"
                    :items="capitanes"
                    item-text="nombre"
                    item-value="id"
                    label="Nombre Capitán"
                    dense
                  >
                  </v-select>
                  <v-text-field
                    v-if="capitanes.length == 0"
                    label="Nombre Capitán"
                    placeholder="Nombre Capitán"
                    v-model="carga.capitan"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-if="tecnicos.length > 0"
                    v-model="carga.tecnico_wellboat"
                    :items="tecnicos"
                    item-text="nombre"
                    item-value="id"
                    label="Técnico Wellboat"
                    dense
                  >
                  </v-select>
                  <v-text-field
                    v-if="tecnicos.length == 0"
                    label="Técnico Wellboat"
                    placeholder="Técnico Wellboat"
                    v-model="carga.tecnico_wellboat"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Encargado Smolt Cliente"
                    placeholder="Encargado Smolt Cliente"
                    v-model="carga.encargado_smolt"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- alert bitacora de carga -->
              <v-row v-if="alert_carga.mostrar">
                <v-col>
                  <v-alert border="left" outlined text :type="alert_carga.tipo">
                    <v-row>
                      <v-col>
                        <h4>{{ alert_carga.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn
                          :color="alert_carga.color_boton"
                          elevation="2"
                          small
                          dark
                          @click="cerrar_alert('carga')"
                          >Cerrar</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_carga.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_carga.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de carga-->
              <v-row>
                <v-col cols="6">
                  <v-btn
                    color=""
                    @click="guardarCarga(1)"
                    >GUARDAR TEMPORAL</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="guardarCarga(0)"
                    >GUARDAR CARGA</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- bitacora de traslado -->
          <v-expansion-panel v-if="mostrar.traslado">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              <div>
                BITACORA DE TRASLADO SMOLT
                <v-icon color="green" title="Guadado Definitivo" v-if="traslado.temporal == 0">mdi-check-circle</v-icon>
                <v-icon color="orange" title="Guadado Temporal" v-if="traslado.temporal == 1">mdi-clock</v-icon>
              </div>
              
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Folio Parte"
                    label="Folio Parte"
                    v-model="parte.folio"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Guia Despacho 1era Carga"
                    label="Guia Despacho 1"
                    v-model="traslado.guia_despacho"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Guia Despacho 2da Carga"
                    label="Guia Despacho 2"
                    v-model="traslado.guia_despacho_2"
                    dense
                    :disabled="!doble_carga"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaGuiaTraslado"
                        label="Fecha guía de despacho"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="traslado.fecha_despacho"
                      @input="menu1 = false"
                      locale="es-cl"
                      prev-icon="chevron_left"
                      next-icon="chevron_right"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mx-0 mt-1">
                <v-col cols="3">
                  <v-text-field
                    placeholder="Centro Carga"
                    label="Centro Carga"
                    v-model="traslado.centro_carga"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    placeholder="Centro Descarga"
                    label="Centro Descarga"
                    v-model="traslado.centro_descarga"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    placeholder="Hora Inicio"
                    label="Hora Inicio"
                    v-model="traslado.hora_inicio_spanish"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    placeholder="Hora Termino"
                    label="Hora Termino"
                    v-model="traslado.hora_termino_spanish"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Mediciones de parámetros
                </legend>
                <v-btn @click="addMedicionTraslado" color="green"
                  ><v-icon>exposure_plus_1</v-icon></v-btn
                >
                <v-simple-table dense class="mx-0 elevation-3 text-center">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          rowspan="2"
                          class="text-center blue-grey darken-4 white-text"
                        >
                          Número
                        </th>
                        <th
                          rowspan="2"
                          class="text-center blue-grey darken-4 white-text"
                        >
                          HORA
                        </th>
                        <th
                          colspan="4"
                          class="text-center blue-grey darken-4 white-text"
                          v-for="bodega in bodegas"
                          :key="bodega.id"
                        >
                          {{ bodega.nombre }}
                        </th>
                        <th
                          rowspan="2"
                          class="text-center blue-grey darken-4 white-text"
                        >
                          Acciones
                        </th>
                      </tr>
                      <tr>
                        <template v-for="i in bodegas.length">
                          <th
                            class="text-center blue-grey darken-4 white-text"
                            :key="`${i}head_ox`"
                          >
                            O2 (mg/L)
                          </th>
                          <th
                            class="text-center blue-grey darken-4 white-text"
                            :key="`${i}head_ph`"
                          >
                            PH
                          </th>
                          <th
                            class="text-center blue-grey darken-4 white-text"
                            :key="`${i}head_uv`"
                          >
                            UV (ml/cm2)
                          </th>
                          <th
                            class="text-center blue-grey darken-4 white-text"
                            :key="`${i}head_temp`"
                          >
                            T (°C)
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      

                      <tr
                      v-for="(parametro,idx1) in traslado.parametros"
                      :key="parametro.numero"
                    >
                      <td>{{ parametro.numero }}</td>
                      <td style="border-right: 1px solid black; width: 180px">
                        <v-datetime-picker
                          label="Hora Medición"
                          v-model="parametro.hora"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{
                            locale: 'es-es',
                            prevIcon: 'chevron_left',
                            nextIcon: 'chevron_right',
                          }"
                          :timePickerProps="{ format: '24hr' }"
                        >
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <template v-for="bodega in parametro.bodegas">
                        <td :key="`${bodega.id}-o2`">
                          <v-text-field
                            v-model="bodega.oxigeno"
                            type="number"
                            step="0.1"
                            class="input-right"
                          ></v-text-field>
                        </td>
                        <td :key="`${bodega.id}-ph`">
                          <v-text-field
                            v-model="bodega.ph"
                            type="number"
                            class="input-right"
                          ></v-text-field>
                        </td>
                        <td :key="`${bodega.id}-uv`">
                          <v-text-field
                            v-model="bodega.uv"
                            type="number"
                            class="input-right"
                          ></v-text-field>
                        </td>
                        <td
                          style="border-right: 1px solid black"
                          :key="`${bodega.id}-t`"
                        >
                          <v-text-field
                            v-model="bodega.temperatura"
                            type="number"
                            class="input-right"
                          ></v-text-field>
                        </td>
                      </template>
                      <td>
                        <a @click="deleteParametroTraslado(idx1)"
                          ><span class="material-icons">delete</span></a
                        >
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </fieldset>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    solo
                    counter="500"
                    label="Observaciones"
                    v-model="traslado.observaciones"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-if="capitanes.length > 0"
                    v-model="traslado.capitan"
                    :items="capitanes"
                    item-text="nombre"
                    item-value="id"
                    label="Nombre Capitán"
                    dense
                  >
                  </v-select>
                  <v-text-field
                    v-if="capitanes.length == 0"
                    label="Nombre Capitán"
                    placeholder="Nombre Capitán"
                    v-model="traslado.capitan"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-if="tecnicos.length > 0"
                    v-model="traslado.tecnico_wellboat"
                    :items="tecnicos"
                    item-text="nombre"
                    item-value="id"
                    label="Técnico Wellboat"
                    dense
                  >
                  </v-select>
                  <v-text-field
                    v-if="tecnicos.length == 0"
                    label="Técnico Wellboat"
                    placeholder="Técnico Wellboat"
                    v-model="traslado.tecnico_wellboat"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Supervisor"
                    placeholder="Supervisor"
                    v-model="traslado.supervisor"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- alert bitacora de traslado -->
              <v-row v-if="alert_traslado.mostrar">
                <v-col>
                  <v-alert
                    border="left"
                    outlined
                    text
                    :type="alert_traslado.tipo"
                  >
                    <v-row>
                      <v-col>
                        <h4>{{ alert_traslado.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn
                          :color="alert_traslado.color_boton"
                          elevation="2"
                          small
                          dark
                          @click="cerrar_alert('traslado')"
                          >Cerrar</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_traslado.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_traslado.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de traslado-->
              <v-row>
                <v-col cols="6">
                  <v-btn
                    color=""
                    @click="guardarTraslado(1)"
                    >GUARDAR TEMPORAL</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="guardarTraslado(0)"
                    >GUARDAR TRASLADO</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- bitacora de descarga -->
          <v-expansion-panel v-if="mostrar.descarga">
            <v-expansion-panel-header color="#263238" style="color: #fff;">
              <div>
                BITACORA DE DESCARGA SMOLT
                <v-icon color="green" title="Guadado Definitivo" v-if="descarga.temporal == 0">mdi-check-circle</v-icon>
                <v-icon color="orange" title="Guadado Temporal" v-if="descarga.temporal == 1">mdi-clock</v-icon>
              </div>
              
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mx-0 mt-1">
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Folio Parte" label="Folio Parte"
                    v-model="parte.folio" dense readonly></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Fecha Folio" label="Fecha Folio"
                  :value="descarga.fecha_despacho | format_fecha" dense readonly></v-text-field>
                </v-col>
                <v-col cols="4"></v-col>
              </v-row>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">INFORMACION GENERAL</legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Cliente" label="Cliente"
                      v-model="descarga.cliente" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Centro Destino / Descarga"
                      label="Centro Destino / Descarga" v-model="descarga.acopio" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Recalada" label="Hora Recalada"
                      v-model="descarga.recalada" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Atraco" label="Hora Atraco"
                      v-model="descarga.atraco" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Inicio" label="Hora Inicio"
                      v-model="descarga.hora_inicio" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Termino" label="Hora Termino"
                      v-model="descarga.hora_termino" dense readonly></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field prepend-icon="mdi-barcode" placeholder="Especie" label="Especie"
                      v-model="descarga.especie" dense readonly></v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Parámetros medioambientales previo a descarga
                </legend>
                <v-row>
                  <v-col cols="4">
                    <v-text-field dense prepend-icon="mdi-seal" label="Saturacion" placeholder="Saturacion" suffix="%"
                      type="number" v-model="descarga.saturacion_centro"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field dense prepend-icon="mdi-temperature-celsius" label="Temperatura" placeholder="Temperatura"
                      type="number" suffix="° C" v-model="descarga.temperatura_centro"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field dense prepend-icon="mdi-seal" label="Oxigeno" placeholder="Oxigeno" suffix="mg/L"
                      type="number" v-model="descarga.oxigeno_centro"></v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
              <fieldset class="px-2 py-2 elevation-3">
                <legend class="rounded-sm px-2 py-1">
                  Detalle distribución de descarga
                </legend>
                <v-btn @click="addDescarga" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center blue-grey darken-4 white-text">
                          N°
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Bodega
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora Inicio
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Hora Término
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          N° Jaula
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          N° Smolt
                        </th>
                        <th class="text-center blue-grey darken-4 white-text">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(descarga,index) in descarga.descargas_jaulas" :key="descarga.numero">
                        <td>{{ descarga.numero }}</td>
                        <td>
                          <v-select v-model="descarga.bodegas_id" :items="bodegas" item-text="nombre"
                            item-value="id" label="Destino">
                          </v-select>
                        </td>
                        <td>
                          <v-datetime-picker label="Inicio (Fecha Hora)" v-model="descarga.hora_inicio"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td>
                          <v-datetime-picker label="Termino (Fecha Hora)" v-model="descarga.hora_termino"
                            dateFormat="dd-MM-yyyy" :datePickerProps="{
                              locale: 'es-es',
                              prevIcon: 'chevron_left',
                              nextIcon: 'chevron_right',
                            }" :timePickerProps="{ format: '24hr' }">
                            <template slot="dateIcon">
                              <v-icon>calendar_today</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>access_time</v-icon>
                            </template>
                          </v-datetime-picker>
                        </td>
                        <td>
                          <v-text-field v-model="descarga.jaula"></v-text-field>
                        </td>
                        <td>
                          <v-text-field v-model="descarga.smolts"></v-text-field>
                        </td>
                        <td>
                          <a @click="eliminarDescargaJaula(index)"><span class="material-icons">delete</span></a>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </fieldset>
              <v-row>
                <v-col cols="12">
                  <v-textarea solo counter="500" label="Observaciones" v-model="descarga.observaciones"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select v-if="capitanes.length > 0" v-model="descarga.capitan" :items="capitanes" item-text="nombre"
                    item-value="id" label="Nombre Capitán" dense>
                  </v-select>
                  <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                    v-model="descarga.capitan" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-if="tecnicos.length > 0" v-model="descarga.tecnico" :items="tecnicos"
                    item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                  </v-select>
                  <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                    v-model="descarga.tecnico" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field dense prepend-icon="mdi-seal" placeholder="Jefe Centro" label="Jefe Centro"
                    v-model="descarga.encargado_acopio"></v-text-field>
                </v-col>
              </v-row>
              <!-- alert bitacora de descarga -->
            <v-row v-if="alert_descarga.mostrar">
                <v-col>
                  <v-alert border="left" outlined text :type="alert_descarga.tipo">
                    <v-row>
                      <v-col>
                        <h4>{{ alert_descarga.mensaje }}</h4>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn :color="alert_descarga.color_boton" elevation="2" small dark
                          @click="cerrar_alert('descarga')">Cerrar</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="alert_descarga.errores.length > 0">
                      <v-col>
                        <ul>
                          <li v-for="e in alert_descarga.errores" :key="e">
                            {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                    <!--<v-row v-if="alert_descarga.warnnings.length > 0">
                      <v-col>
                        <ul >
                          <li v-for="e in alert_descarga.warnnings" :key="e" style="background-color: #8ecae6; color:#3c3c3c; border-radius: 5px; padding:1em; list-style-type:none;">
                            - {{ e }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>-->
                  </v-alert>
                </v-col>
              </v-row>
              <!-- alert bitacora de descarga-->
              <v-row>
                <v-col cols="6">
                  <v-btn color="" @click="guardarDescarga(1)">GUARDAR TEMPORAL</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="green" @click="guardarDescarga(0)">GUARDAR DESCARGA</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- alert finalizar servicio -->
    <v-row v-if="alert_finalizar.mostrar">
      <v-col>
        <v-alert border="left" outlined text :type="alert_finalizar.tipo">
          <v-row>
            <v-col>
              <h4>{{ alert_finalizar.mensaje }}</h4>
            </v-col>
            <v-col class="text-right">
              <v-btn :color="alert_finalizar.color_boton" elevation="2" small dark
                @click="cerrar_alert('finalizar')">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="alert_finalizar.errores.length > 0">
            <v-col>
              <ul>
                <li v-for="e in alert_finalizar.errores" :key="e">
                  {{ e }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <!-- alert finalizar servicio-->

    <v-row v-if="mostrar.finalizar_servicio">
      <v-col cols="6">
        <v-file-input v-model="archivos" label="Adjuntar documentación" show-size counter multiple
          prepend-icon="attach_file" chips accept=".pdf"></v-file-input>
      </v-col>
      <v-col cols="12">
        <v-btn color="green" @click="finalizarServicioWellboat" :disabled="archivos.length == 0">FINALIZAR SERVICIO
          WELLBOAT</v-btn>
      </v-col>
    </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TicketBitacoraNew from "@/components/TicketBitacoraNew.vue";
import { mapState } from "vuex";
export default {
  data: () => ({
    parte: {},
    carga: {
      parte: {},
      lances: [],
      bodegas: [],
    },
    traslado: {},
    descarga: {},
    temporales: [],
    archivos:[],
    parameters: [],
    tecnicos: [],
    capitanes: [],
    bodegas: [],
    empresas: [],
    especies: [],
    centros: [],
    mostrar: {
      carga: false,
      traslado: false,
      descarga: false,
      descarga_planta: false,
      finalizar_servicio:false,
      segundo_centro_descarga:false,
    },
    alert_carga: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_traslado: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_descarga: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    alert_finalizar: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    doble_carga: false,
    nbodega1: "N° sello babor",
    nbodega2: "N° sello central",
    nbodega3: "N° sello estribor",
    panel: 0,
    url_pdf_carga: null,
    menu1:false,
    ids_bitacoras:[],
    traslados_parametros_ids_eliminar:[],
    cargas_bodegas_ids_eliminar:[],
    cargas_parametros_ids_eliminar:[],
    descargas_jaulas_ids_eliminar:[]
  }),
  async mounted() {
    this.empresas = this.$store.getters["empresa/getActive"];
    /*this.bodegas = this.$store.state.user.wellboat.bodegas.map((b) => {
        return {
          bodega: b.bodega,
          m3: b.m3,
        };
      });*/
    this.especies = this.$store.getters["especie/getActive"];
    await this.loadParameters();
    await this.cargarPartesTemporales();
  },
  methods: {
    async loadParameters() {
      let url = `${this.base_url}tipos_bitacoras/${2}/cargar_parametros`;
      await this.axios.get(url, this.headers).then((r) => {
        this.parameters = r.data;
      });
    },
    async cargarPartesTemporales() {
      // console.log("cargando partes temporales ...");
      let url = `${this.base_url}partes/temporales/wellboat/${this.user.wellboat.id}/tipo_parte/3`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.temporales = response.data.data;
          if (this.temporales.length > 0) {
            this.seleccionParte(this.temporales[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async seleccionParte(parte) {
      // console.log("parte", parte.id);
      this.parte = parte;
      if (this.parte.wellboats_id == 7) {
        // don mauro
        this.nbodega1 = "N° sello Proa";
        this.nbodega2 = "N° sello Popa";
      }

      //actualizar parte seleccionado (para mostrar en color visualmente el parte seleccionado)
      let idx = this.temporales.findIndex((t) => t.id == this.parte.id);
      this.temporales.forEach((p, i) => {
        p.seleccionado = i == idx ? true : false;
      });

      //carga de bodegas del wellboat
      await this.cargarBodegas();

      //cargando bitacoras del parte
      await this.cargarBitacoras();

      

      await this.cargarTecnicos();
      await this.cargarCapitanes();

      // console.log(`es descarga: ${this.parte.es_descarga}`);
    },

    async cargarBitacoras() {
      this.carga = {
        parte: {},
        lances: [],
        bodegas: [],
      };
      this.traslado = {};
      this.descarga = {};
      //this.descarga_planta = {};

      let url = `${this.base_url}partes/${this.parte.id}/bitacoras`;
      await this.axios.get(url, this.headers_json).then((r) => {
        console.log("data", r.data);
        //indica si es un parte con doble carga
        this.doble_carga = r.data.doble_carga;
        //bitacora de carga
        this.carga = r.data.carga;
        /*if (this.parte.termino_cam) {
            this.termino_cam = this.moment(this.parte.termino_cam).toDate();
            }*/
        //bitacora de traslado
        this.formatearFechaParaDatepicker("carga");
        this.setearParametros();
        this.cargarCentrosEmpresa();

        if (r.data.traslado != null) {
          this.traslado = r.data.traslado;
          this.traslado.hora_inicio_spanish = this.$options.filters.format_date_spanish(
            this.traslado.hora_inicio
          );
          this.traslado.hora_termino_spanish = this.$options.filters.format_date_spanish(
            this.traslado.hora_termino
          );
          this.formatearParametrosTraslado();
        }
        //bitacora de traslado
        
        //bitacora de carga

        //bitacora de traslado
        /*if (r.data.traslado != null) {
          this.traslado = r.data.traslado;
          this.traslado.hora_inicio_spanish = this.$options.filters.format_date_spanish(
            this.traslado.hora_inicio
          );
          this.traslado.hora_termino_spanish = this.$options.filters.format_date_spanish(
            this.traslado.hora_termino
          );
          this.formatearFechaParaDatepicker("traslado");
        }*/
        //bitacora de traslado

        //bitacora de descarga
        if (r.data.descarga != null) {
          this.descarga = r.data.descarga;
          this.descarga.recalada = this.$options.filters.format_date_spanish(
            this.descarga.recalada
          );
          this.descarga.atraco = this.$options.filters.format_date_spanish(
            this.descarga.atraco
          );
          this.descarga.hora_inicio = this.$options.filters.format_date_spanish(
            this.descarga.hora_inicio
          );
          this.descarga.hora_termino = this.$options.filters.format_date_spanish(
            this.descarga.hora_termino
          );
          this.formatearFechaParaDatepicker("descarga");
        }
        //bitacora de descarga

        this.mostrar = r.data.mostrar;

        this.ids_bitacoras = r.data.ids_bitacoras;


        /*if (this.parte.es_descarga == 1) {
          this.panel = this.traslado.temporal == 1 ? 1 : 2;
        }*/
      });
    },

    async cargarBodegas() {
      let fecha_viaje = this.parte.recalada
        ? this.parte.recalada
        : this.parte.zarpe;
      let url = `${this.base_url}wellboats/${this.parte.wellboats_id}/bodegas/${fecha_viaje}`;
      await this.axios.get(url, this.headers_json).then((r) => {
        this.bodegas = r.data.data;
      });
    },

    async cargarTecnicos() {
      let url = `${this.base_url}cargas/tecnicos/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.tecnicos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async cargarCapitanes() {
      let url = `${this.base_url}cargas/capitanes/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.capitanes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async cargarCentrosEmpresa() {
      let url = `${this.base_url}empresas/${this.parte.empresas_id}/centros`;
      await this.axios
        .get(url, this.headers_json)
        .then((r) => {
          console.log("centros de cultivo", r.data.data);
          this.centros = r.data.data;
        })
        .catch((e) => {
          console.log("error al cargar centros", e);
        });
    },

    agregarCargaBodega() {
      let n = this.carga.bodegas.length;
      let bodega = {
        id: null,
        numero: n + 1,
        cargas_id: this.carga.id,
        bodegas_id: null,
        peces: null,
        peso: null,
        biomasa: null,
        densidad: null,
        lances: null,
        jaula: null,
        estanques: null,
        hora_inicio: null,
        hora_termino: null,
      };
      this.$set(this.carga.bodegas, n, bodega);
    },

    async eliminarBodega(index) {
      //const index = this.carga.lances.indexOf(item);
      if(this.carga.bodegas[index].id){
        this.cargas_bodegas_ids_eliminar.push(this.carga.bodegas[index].id);
      }
      this.carga.bodegas.splice(index, 1);
      //enumerar lances
      let num = 1;
      this.carga.bodegas.forEach((b, i) => {
        this.$set(this.carga.bodegas[i], "numero", num);
        num++;
      });
    },

    formatearParametrosTraslado(key = "hora") {
      let array = [...this.traslado.parametros];
      const unikeys = [
        ...new Map(array.map((item) => [item[key], item])).values(),
      ];
      let params = [];
      

      unikeys.forEach((hora) => {
        let bdgas = [];
        this.bodegas.forEach((b) => {
          let find = this.traslado.parametros.find(
            (p) => p.hora == hora.hora && p.bodegas_id == b.id
          );

          if (find) {
            bdgas.push(find);
          } else {
            bdgas.push({
              oxigeno: null,
              ph: null,
              uv: null,
              temperatura: null,
              bodegas_id: b.id,
              id: this.generarKey(5),
            });
          }
        });
        params.push({
          id: null,
          menu1: false,
          traslados_id: this.traslado.id,
          numero: hora.numero,
          hora: this.$options.filters.format_string_a_fecha(hora.hora),
          bodegas: bdgas,
        });
      });

      this.$set(this.traslado,'parametros',params)
    },

    recalcular(item, index) {
      let bodega = item;
      let bod = this.bodegas.find((b) => b.id == item.bodegas_id);
      let biomasa = Math.round(bodega.peces * bodega.peso);
      bodega.biomasa = biomasa;
      bodega.densidad = (biomasa / bod.m3).toFixed(2);
      //console.log('bodega',bod)
      //console.log('bodegas',this.bodegas)
      this.$set(this.carga.bodegas, index, bodega);
    },

    agregarLance() {
      let n = this.carga.lances.length;
      let lance = {
        id: null,
        cargas_id: this.carga.id,
        numero: n + 1,
        hora_inicio: null,
        //'hora_medicion': '00:00',
        hora_medicion: null,
        oxigeno: null,
        saturacion: null,
        temperatura: null,
        peces: null,
        hora_termino: null,
        carga_efectiva: null,
      };
      this.$set(this.carga.lances, n, lance);
    },
    eliminarLance(index) {
      //const index = this.carga.lances.indexOf(item);
      if(this.carga.lances[index].id){
        this.cargas_parametros_ids_eliminar.push(this.carga.lances[index].id);
      }
      this.carga.lances.splice(index, 1);
      //enumerar lances
      let num = 1;
      this.carga.lances.forEach((b, i) => {
        this.$set(this.carga.lances[i], "numero", num);
        num++;
      });
    },

    setearParametros() {
      this.parameters.forEach((p, idx) => {
        let par = this.carga.parametros.find(
          (item) => item.parametros_id == p.id
        );
        if (par) {
          this.$set(this.parameters[idx], "valor", par.parametros_opciones_id);
        }
      });
    },

    async guardarCarga(temporal = 1) {
      this.cerrar_alert("carga");
      this.carga.temporal = temporal;
      this.carga.parametros = this.parameters;

      //reformateando fechas
      this.carga.lances.forEach((l) => {
        l.hora_inicio = this.$options.filters.format_fecha_hora(l.hora_inicio);
        l.hora_termino = this.$options.filters.format_fecha_hora(
          l.hora_termino
        );
        l.hora_medicion = this.$options.filters.format_fecha_hora(
          l.hora_medicion
        );
      });

      this.carga.bodegas.forEach((b) => {
        b.hora_inicio = this.$options.filters.format_fecha_hora(b.hora_inicio);
        b.hora_termino = this.$options.filters.format_fecha_hora(
          b.hora_termino
        );
      });

      this.cargas_bodegas_ids_eliminar.forEach(async (id) => {
        await this.axios.delete(`${this.base_url}cargas_bodegas/${id}`,this.headers_json)
      });

      this.cargas_parametros_ids_eliminar.forEach(async (id) => {
        await this.axios.delete(`${this.base_url}cargas_parametros/${id}`,this.headers_json)
      });

      this.cargas_bodegas_ids_eliminar = [];
      this.cargas_parametros_ids_eliminar = [];

      /*let fecha_cam = this.termino_cam
        ? this.moment(this.termino_cam).format("YYYY-MM-DD HH:mm")
        : null;

      this.$set(this.carga, "termino_cam", fecha_cam);*/
      if (this.carga.es_nueva) {
        this.carga.natacion = this.buscar_valor_parametro(1);
        this.carga.boqueo = this.buscar_valor_parametro(2);
        this.carga.descamacion = this.buscar_valor_parametro(3);
        this.carga.esquinados = this.buscar_valor_parametro(4);
        this.carga.lobos = this.buscar_valor_parametro(5);
        this.carga.heridas = this.buscar_valor_parametro(6);
        this.carga.mortalidad_cerco = this.buscar_valor_parametro(7);
      }
      console.log("carga:", this.carga);

      //let url = this.carga.id != null? `${this.base_url}cargas/${this.carga.id}`:`${this.base_url}cargas`
      let url = `${this.base_url}cargas/guardar`;
      await this.axios
        .post(url, this.carga, this.headers_json)
        .then((r) => {
          this.alert_carga.mostrar = true;
          this.alert_carga.tipo = "success";
          this.alert_carga.color_boton = "green";
          this.alert_carga.mensaje = r.data.message;
          //this.carga = r.data.data;
          //this.formatearFechaParaDatepicker("carga");
          this.cargarBitacoras();

          this.url_pdf_carga = r.data.url_pdf;
          if (this.url_pdf_carga != null) {
            window.open(`${this.storage_url}${this.url_pdf_carga}`);
          }
        })
        .catch((e) => {
          this.alert_carga.mostrar = true;
          this.alert_carga.tipo = "error";
          this.alert_carga.color_boton = "red";
          this.alert_carga.mensaje = e.response.data.message;
          this.alert_carga.errores = e.response.data.errors;
          this.carga.temporal = 1;
        });
    },

    async guardarTraslado(temporal = 1) {
      this.cerrar_alert("traslado");
      this.traslado.temporal = temporal;
      this.traslado.doble_carga = this.doble_carga;
      let url = `${this.base_url}traslados/guardar`;

      this.traslado.parametros.forEach((p) => {
        p.hora = this.$options.filters.format_fecha_hora(p.hora);
      });

      this.traslados_parametros_ids_eliminar.forEach(async (id) => {
        await this.axios.delete(`${this.base_url}traslados_parametros/${id}`,this.headers_json)
      });

      this.traslados_parametros_ids_eliminar = [];

      await this.axios
        .post(url, this.traslado, this.headers_json)
        .then((r) => {
          this.alert_traslado.mostrar = true;
          this.alert_traslado.tipo = "success";
          this.alert_traslado.color_boton = "green";
          this.alert_traslado.mensaje = r.data.message;
          this.seleccionParte(this.parte);
          //this.traslado = r.data.data;
          //this.formatearParametrosTraslado();
          this.url_pdf_traslado = r.data.url_pdf;
          if (this.url_pdf_traslado != null) {
            window.open(`${this.storage_url}${this.url_pdf_traslado}`);
          }
        })
        .catch((e) => {
          this.alert_traslado.mostrar = true;
          this.alert_traslado.tipo = "error";
          this.alert_traslado.color_boton = "red";
          this.alert_traslado.mensaje = e.response.data.message;
          this.alert_traslado.errores = e.response.data.errors;
          this.traslado.temporal = 1;
        });
    },

    async guardarDescarga(temporal = 1) {
      this.cerrar_alert("descarga");
      this.descarga.temporal = temporal;

      let url = `${this.base_url}descargas/guardar`;

      //reformateando fechas
      this.descarga.descargas_jaulas.forEach((j) => {
        j.hora_inicio = this.$options.filters.format_fecha_hora(j.hora_inicio);
        j.hora_termino = this.$options.filters.format_fecha_hora(
          j.hora_termino
        );
      });

      this.descargas_jaulas_ids_eliminar.forEach(async id=>{
        await this.axios.delete(`${this.base_url}descargas_jaulas/${id}`,this.headers_json)
      })
      this.descargas_jaulas_ids_eliminar = []

      await this.axios
        .post(url, this.descarga, this.headers_json)
        .then((r) => {
          this.alert_descarga.mostrar = true;
          this.alert_descarga.tipo = "success";
          this.alert_descarga.color_boton = "green";
          this.alert_descarga.mensaje = r.data.message;
          this.alert_descarga.warnnings = r.data.warnnings;
          console.log("r.data:", r.data);
          //this.descarga = r.data.data;
          //this.formatearFechaParaDatepicker("descarga");
          this.cargarBitacoras()
          this.url_pdf_descarga = r.data.url_pdf;
          if (this.url_pdf_descarga != null) {
            window.open(`${this.storage_url}${this.url_pdf_descarga}`);
          }
        })
        .catch((e) => {
          this.alert_descarga.mostrar = true;
          this.alert_descarga.tipo = "error";
          this.alert_descarga.color_boton = "red";
          this.alert_descarga.mensaje = e.response.data.message;
          this.alert_descarga.errores = e.response.data.errors;
          //this.descarga.temporal = 1;
          this.formatearFechaParaDatepicker("descarga");
        });
    },

    addMedicionTraslado() {
      let n = this.traslado.parametros.length;
      let parametro = {
        id: null,
        menu1: false,
        traslados_id: null,
        numero: n + 1,
        hora: null,
        bodegas: [],
      };
      this.bodegas.forEach((b) => {
        // parametro['b'+b.bodega.id] = {oxigeno: 0, ph: 0, uv: 0, temperatura: 0}
        parametro.bodegas.push({
          oxigeno: null,
          ph: null,
          uv: null,
          temperatura: null,
          bodegas_id: b.id,
          id: this.generarKey(5),
        });
      });
      this.traslado.parametros.push(parametro);
    },

    deleteParametroTraslado(index) {
      //const index = this.traslado.parametros.indexOf(item);
      console.log('index '+index)
      console.log(this.traslado.parametros[index])
      this.traslado.parametros[index].bodegas.forEach(async b => {
        if(!isNaN(b.id)){
          this.traslados_parametros_ids_eliminar.push(b.id);

          //console.log('id '+b.id)
          /*await this.axios.delete(`${this.base_url}traslados_parametros/${b.id}`,this.headers_json).then(res => {
            console.log(res.data)
            console.log('Eliminado id: '+b.id)
          })*/
        }
      });
      this.traslado.parametros.splice(index, 1);
      //enumerar parametros traslado
      let num = 1;
      this.traslado.parametros.forEach((p, i) => {
        this.$set(this.traslado.parametros[i], "numero", num);
        num++;
      });
    },

    addDescarga() {
      let n = this.descarga.descargas_jaulas.length;
      let descarga = {
        id: null,
        menu1: false,
        menu2: false,
        descargas_id: null,
        //bodega: null,
        bodegas_id:null,
        numero: n + 1,
        hora_inicio: null,
        hora_termino: null,
        jaula: null,
        smolts: null,
        key: this.generarKey(5),
      };
      this.descarga.descargas_jaulas.push(descarga);
      // this.$set(this.descarga.jaulas, n, descarga);
    },

    async eliminarDescargaJaula(index) {
      if(this.descarga.descargas_jaulas[index].id){
        this.descargas_jaulas_ids_eliminar.push(this.descarga.descargas_jaulas[index].id)
      }
      this.descarga.descargas_jaulas.splice(index, 1);
      //enumerar descargas jaulas
      let num = 1;
      this.descarga.descargas_jaulas.forEach((p, i) => {
        this.$set(this.descarga.descargas_jaulas[i], "numero", num);
        num++;
      });
    },

    async saveTraslado(temporal = 1) {
      // validation
      this.errors = [];
      if (!this.parte.folio) {
        this.errors.push("Debe elegir un parte de viaje");
      }
      if (this.traslado.parametros.length == 0) {
        this.errors.push("Debe ingresar al menos una medición");
      } else {
        let traslado = this.traslado.parametros[0];
        if (!traslado.hora) {
          this.errors.push("Debe ingresar hora de la medición");
        }
      }
      if (!temporal) {
        if (!this.traslado.tecnico_wellboat) {
          this.errors.push("Debe indicar técnico wellboat");
        }
        if (!this.traslado.supervisor) {
          this.errors.push("Debe indicar supervisor de peces");
        }
        if (!this.traslado.capitan) {
          this.errors.push("Debe indicar capitán wellboat");
        }
        if (!this.traslado.centro_descarga) {
          this.errors.push("Falta centro de descarga");
        }
        if (!this.traslado.hora_inicio) {
          this.errors.push("Falta hora de inicio");
        }
        if (!this.traslado.hora_termino) {
          this.errors.push("Falta hora de termino");
        }
        if (!this.traslado.fecha_despacho) {
          this.errors.push("Falta fecha de despacho");
        }
        if (!this.traslado.guia_despacho) {
          this.errors.push("Debe indicar guía de despacho 1era Carga");
        }
        if (!this.traslado.guia_despacho_2 && this.doble_carga) {
          this.errors.push("Debe indicar guía de despacho 2da Carga");
        }
      }
      let parametros = [];
      this.traslado.parametros.forEach((p) => {
        //p.hora = this.moment(p.hora).format("YYYY-MM-DD HH:mm:ss");
        /*
            if(p.hora < this.traslado.hora_inicio){
              this.errors.push("Hora de medición debe ser mayor que hora de inicio");
            }
            */
        let parametro = Object.assign({}, p);
        parametro.hora = this.moment(parametro.hora).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        parametros.push(parametro);
        //parametros.push(p);
      });
      if (this.errors.length > 0) {
        //this.validation_message = this.errors.join(". ");
        this.validation_message =
          "Revise los siguientes problemas de validación:";
        this.showSnackBar(true);
        return;
      }
      let tecnico_wellboat = this.traslado.tecnico_wellboat;
      if (tecnico_wellboat) {
        if (typeof this.traslado.tecnico_wellboat === "object") {
          tecnico_wellboat = this.traslado.tecnico_wellboat.nombre;
        }
      }
      let capitan = "";
      if (this.traslado.capitan) {
        if (typeof this.traslado.capitan == "object") {
          if (this.traslado.capitan.nombre != undefined) {
            capitan = this.traslado.capitan.nombre;
          }
        } else {
          capitan = this.traslado.capitan;
        }
      }

      let url = `${this.base_url}traslados`;
      let trasladoData = {
        main: {
          partes_id: this.traslado.partes_id,
          wellboats_id: this.traslado.wellboats_id,
          observaciones: this.traslado.observaciones,
          tecnico_wellboat: tecnico_wellboat,
          supervisor: this.traslado.supervisor,
          capitan: capitan,
          centro_carga: this.traslado.centro_carga,
          centro_descarga: this.traslado.centro_descarga,
          //hora_inicio: this.reformatDateTime(this.traslado.hora_inicio),
          //hora_termino: this.reformatDateTime(this.traslado.hora_termino),
          hora_inicio: this.traslado.hora_inicio,
          hora_termino: this.traslado.hora_termino,
          temporal: temporal,
          fecha_despacho: this.traslado.fecha_despacho,
          guia_despacho: this.traslado.guia_despacho,
          guia_despacho_2: this.traslado.guia_despacho_2,
        },
        parametros: parametros,
      };
      let response = null;
      if (this.traslado.id > 0) {
        url = url + "/" + this.traslado.id;
        try {
          response = await this.axios.put(url, trasladoData, this.headers_json);
          //this.onClickTicket(this.traslado.parte);
          this.onClickTicket(this.parte);
          this.showSnackBar(false);
        } catch (error) {
          response = error.response;
          let msg = response.data.data;
          this.validation_message =
            response.status + ": " + response.data.message + ". " + msg;
          this.showSnackBar(true);
        }
      } else {
        try {
          response = await this.axios.post(
            url,
            trasladoData,
            this.headers_json
          );
          //this.onClickTicket(this.traslado.parte);
          this.onClickTicket(this.parte);
          this.showSnackBar(false);
        } catch (error) {
          response = error.response;
          if (response == undefined) {
            this.showSnackBar(false);
            return;
          }
          let msg = response.data.data;
          this.validation_message =
            response.status + ": " + response.data.message + ". " + msg;
          this.showSnackBar(true);
        }
      }
      if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
        window.open(this.storage_url + response.data.url_pdf);
      }
    },

    buscar_valor_parametro(id) {
      let valor = this.parameters.find((p) => p.id == id).valor;
      return valor;
    },

    formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      },

    generarKey(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },

    cerrar_alert(tipo = "") {
      let alert = {
        mostrar: false,
        tipo: "",
        color_boton: "",
        mensaje: "",
        errores: [],
      };
      if (tipo == "carga") {
        this.alert_carga = alert;
      }
      if (tipo == "traslado") {
        this.alert_traslado = alert;
      }
      if (tipo == "descarga") {
        this.alert_descarga = alert;
      }
      if (tipo == "descarga_planta") {
        this.alert_descarga_planta = alert;
      }
      if (tipo == "finalizar") {
        this.alert_finalizar = alert;
      }
    },

    formatearFechaParaDatepicker(bitacora = "") {
      if (bitacora == "carga") {
        //reformateando fechas carga (para mostrar en datepicker)
        this.carga.lances.forEach((l) => {
          l.key = this.generarKey(5);
          l.hora_medicion = this.$options.filters.format_string_a_fecha(
            l.hora_medicion
          );
        });

        this.carga.bodegas.forEach((b, idx) => {
          b.key = this.generarKey(5);
          b.hora_inicio = this.$options.filters.format_string_a_fecha(
            b.hora_inicio
          );
          b.hora_termino = this.$options.filters.format_string_a_fecha(
            b.hora_termino
          );
          b.numero = idx + 1;
        });
      }

      if (bitacora == "traslado") {
        this.traslado.parametros.forEach((p) => {
          p.hora = this.$options.filters.format_string_a_fecha(p.hora);
        });
      }

      if (bitacora == "descarga") {
        this.descarga.descargas_jaulas.forEach((j,idx) => {
          j.numero = idx + 1;
          j.hora_inicio = this.$options.filters.format_string_a_fecha(
            j.hora_inicio
          );
          j.hora_termino = this.$options.filters.format_string_a_fecha(
            j.hora_termino
          );
        });
      }
    },

    async sincronizar() {
      let parte = this.parte;
      if (parte != undefined) {
        let inicio = parte.zarpe.substring(0, 10);
        let termino = parte.recalada.substring(0, 10);
        //console.log(inicio, termino)
        let url = `${this.base_url}turnos/obtener`;
        await this.axios
          .post(url, { fecha: inicio }, this.headers)
          .then((response) => {
            console.log(response);
            if (inicio != termino) {
              this.axios
                .post(url, { fecha: termino }, this.headers)
                .then((response) => {
                  console.log(response);
                  //this.onClickTicket(parte);
                });
            } else {
              //this.onClickTicket(parte);
            }
          });
      }
    },

    async finalizarServicioWellboat() {
      this.cerrar_alert("finalizar");

      let formData = new FormData();
      // let nf = this.archivos.length;
      //let i = 1;
      this.archivos.forEach((f) => {
        //formData.append("doc"+i, f);
        formData.append("files[]", f);
        //i++;
      });
      formData.append("partes_id", this.parte.id);
      // formData.append('num_files', nf)
      // formData.append('archivos',this.archivos)

      formData.append("cargas_id_1", this.ids_bitacoras?.carga_id_1);
      formData.append("cargas_id_2", this.ids_bitacoras?.carga_id_2);
      formData.append("traslados_id", this.ids_bitacoras?.traslado_id);
      formData.append("descargas_id_1", this.ids_bitacoras?.descarga_id_1);
      formData.append("descargas_id_2", this.ids_bitacoras?.descarga_id_2);
      formData.append("descargas_plantas_id", this.ids_bitacoras?.descarga_planta_id);
      formData.append("tipopartes_id", this.parte.tipopartes_id);
      formData.append("folio", this.parte.folio);
      formData.append("wellboats_id", this.parte.wellboats_id);

      let url = `${this.base_url}bitacoras/finalizar`;

      await this.axios
        .post(url, formData, this.headers_file)
        .then((r) => {
          // console.log("r", r);
          this.alert_finalizar.mostrar = true;
          this.alert_finalizar.tipo = "success";
          this.alert_finalizar.color_boton = "green";
          this.alert_finalizar.mensaje = r.data.message;
        })
        .catch((e) => {
          // console.log("err", e);
          this.alert_finalizar.mostrar = true;
          this.alert_finalizar.tipo = "error";
          this.alert_finalizar.color_boton = "red";
          this.alert_finalizar.mensaje = e.response.data.message;
          this.alert_finalizar.errores = e.response.data.errors;
        });
    },
  },
  computed: {
    ...mapState([
      "storage_url",
      "loading",
      "base_url",
      "headers",
      "headers_json",
      "headers_file",
      "user",
    ]),
    totalPeces() {
      let total = 0;
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.peces);
      });
      return total;
    },
    totalPeso() {
      if (this.totalBiomasa != 0 && this.totalPeces != 0) {
        return (this.totalBiomasa / this.totalPeces).toFixed(3);
      }
      return 0;
    },
    totalBiomasa() {
      let total = 0;
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.biomasa);
      });
      return total;
    },
    totalDensidad() {
      let total = 0;
      let m3_bodegas = 0;
      let ids = [];
      this.carga.bodegas.forEach((b) => {
        total += parseFloat(b.biomasa);
        let index = ids.findIndex((id) => id == b.bodegas_id);
        if (index == -1) {
          let m3 = this.bodegas.find((bod) => bod.id == b.bodegas_id)?.m3;
          m3_bodegas += m3;
          ids.push(b.bodegas_id);
        }
      });
      //let densidad = this.user.wellboat.m3;
      return (total / m3_bodegas).toFixed(2);
    },
    fechaGuiaTraslado() {
      return this.formatDate(this.traslado.fecha_despacho);
    },
    texto_centro_uno(){
      return this.mostrar.segundo_centro_descarga == true?'Centro de Descarga 1':'Centro de Descarga'
    }
  },
  components: {
    TicketBitacoraNew,
  },
};
</script>
